import React from 'react'
import { graphql } from 'gatsby'
import { Row, Col } from 'reactstrap';

import Layout from '../components/layout'
import TeamCard from '../components/teamCard/teamCard'
import { team } from '../conf/team'

const About = (props) => {
  const images = require.context('../images/team/', true);


  return (
    <Layout>
      <section className="section-wrapper">
        <header className="section-header">
          <h3 name="title">LEHMANN+PARTNER Informatik AG</h3>
        </header>
        <span name="body">
          <p>
            1995 gegründet, beraten und betreuen wir seither mit einem Team von Wirtschaftsinformatikern und Softwareentwicklern Grossfirmen und Unternehmen mittlerer Grösse aus den verschiedensten Branchen. Wir haben uns auf den Fachbereich HR spezialisiert und verfügen über umfassende Kompetenzen in den Bereichen Lohnbuchhaltung/Payroll, Personalverwaltung, HR Portale und HR Prozessmanagement.
          </p>
          <p>
            Seit Januar 2023 ist LEHMANN+PARTNER Informatik AG Teil der OBT Gruppe. Diese Kooperation ermöglicht es uns, unseren Kunden HR Anforderungen zielsicher und gelingend zu realisieren. Der glückliche Mitarbeitende hängt von vielen Faktoren ab, und wir tun unser Bestes, damit unseren Kunden dies im Bereich HR gelingt. Informatikkenntnisse in Verbindung mit Personalfachwissen ist die Basis, um Personalabteilungen erfolgreich beim Erreichen von Zielen zu unterstützen. Nur wer die Anforderungen seines Gegenübers versteht und in ein grosses Ganzes einordnen kann, ist in der Lage kompetent und zielgerichtet zu beraten.
          </p>
          <br />
          <p>
            Unser Sitz in Luzern liegt zentral in der Schweiz und ist für Kunden gut erreichbar. Unsere Kunden geben stets gerne Referenzauskunft, da wir vereinbarte Kosten, Termine und Funktionalitäten einhalten.
          </p>
        </span>
      </section>
      <section id="team" className="section-wrapper">
        <header className="section-header">
          <h3>Team</h3>
        </header>
        <div id="team">
          <Row>

            <Col className="col-12 col-sm-12 col-md-4 col-lg-3 col-xl-3"><h3>Geschäftsleitung</h3></Col>
            <Col >

              <Row>
                {
                  team.map((person, index) => {
                    let img = images('./' + person.img);
                    if (person.gruppe === 'Ge­schäfts­leitung') {
                      return <TeamCard key={index} name={person.name} img={img} funktion={person.funktion} funktion2={person.funktion2} />
                    }
                  })
                }
              </Row>



            </Col>







          </Row>
        </div>
        <div id="team">
          <Row>


          </Row>
        </div>
        <div id="team">
          <Row>

            <Col className="col-12 col-sm-12 col-md-4 col-lg-3 col-xl-3"><h3>Expertenteam</h3></Col>
            <Col>
              <Row>             {
                team.map((person, index) => {
                  let img = images('./' + person.img);
                  if (person.gruppe === 'Expertenteam') {
                    return <TeamCard key={index} name={person.name} img={img} funktion={person.funktion} funktion2={person.funktion2} />
                  }
                })
              }
              </Row>

            </Col>



          </Row>
        </div>
        <div id="team">
          <Row>

            <Col className="col-12 col-sm-12 col-md-4 col-lg-3 col-xl-3"><h3>Entwicklung</h3></Col>
            <Col>
              <Row>             {
                team.map((person, index) => {
                  let img = images('./' + person.img);
                  if (person.gruppe === 'Entwicklung') {
                    return <TeamCard key={index} name={person.name} img={img} funktion={person.funktion} funktion2={person.funktion2} />
                  }
                })
              }
              </Row>

            </Col>



          </Row>
        </div>
        <div id="team">

        </div>
      </section>
    </Layout>
  )
}

export default About

